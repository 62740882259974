import { defaultJuniper } from './default-juniper-jp';
import { sharedProdAuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedProdAuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.au.eucalyptus.vc',
  restApiUrl: 'https://admin-api.myjuniper.jp',
  assetsUrl:
    'https://storage.googleapis.com/euc-services-prod-juniper-jp-assets',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
  shopifyHostname: undefined,
  userAppUrl: 'https://app.myjuniper.jp',
  doctorsAppUrl: 'https://prescribers.myjuniper.jp',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.myjuniper.jp',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
};
